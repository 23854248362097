import {
  Alert,
  Autocomplete,
  Box,
  Collapse,
  Container,
  Fab,
  Grid,
  TextField,
  Toolbar,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FunctionComponent, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { getCountryFromTimezone } from '../utils/userLocation';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocationData } from '../hooks/useLocationData';
import NotFound from './resources/NotFound';
import CountryResources from './resources/CountryResources';
import { resourceMap } from './resources/resourceMap';
import LoadingSpinner from '../components/LoadingSpinner';
import ScrollTop from '../components/ScrollTop';
import { useModalStateStore } from '../state/modals';
import { removeUtms } from '../utils/common';

const supportedCountryCodes: string[] = Object.keys(resourceMap);

const Resources: FunctionComponent = () => {
  const { t } = useTranslation();
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));

  const { countryCode } = useParams<{ countryCode?: string }>();
  const { isLoaded, getCountryByCode, getCountries } = useLocationData();
  const n = useNavigate();

  const [country, setCountry] = useState<string>(
    countryCode?.toUpperCase() || getCountryFromTimezone()
  );
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/resources',
      title: 'Resources',
      hitCallback: removeUtms,
    });
  }, []);

  useEffect(() => {
    const title: string = document.title;
    document.title = 'Landlord Spotlight - Resources';
    return () => {
      document.title = title;
    };
  }, []);

  useEffect(() => {
    const countries = getCountries();
    if (!isLoaded || !countries) return;

    setOptions(
      countries
        .filter(({ countryCode }) => supportedCountryCodes.includes(countryCode))
        .map(({ flag, countryCode, name }) => ({
          label: `${flag} ${name}`,
          value: countryCode,
        }))
    );
  }, [isLoaded]);

  const countryObj = getCountryByCode(country);

  if (!isLoaded || !options) return <LoadingSpinner />;
  if (!countryObj) return <NotFound />;

  return (
    <>
      <Alert severity="info">
        Have something useful to share? Send it to us through the{' '}
        <a onClick={openContactUsModal}>Contact Form</a> and we'll look into getting in on the list
      </Alert>

      <Container sx={{ padding: { xs: '5px', sm: '10px' } }}>
        <Box>
          <Collapse
            sx={{
              height: { sm: 'auto !important' },
              visibility: { sm: 'inherit' },
              paddingTop: { xs: '10px', sm: 0 },
            }}
            in={filterBoxOpen}
          >
            <Toolbar key="reviews-filter-toolbar">
              <Grid container spacing="10px">
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    autoHighlight={false}
                    clearOnBlur
                    blurOnSelect
                    disableClearable
                    size="small"
                    placeholder={t('components.locationDropdowns.country.label')}
                    value={{
                      label: `${countryObj.flag} ${countryObj.name}`,
                      value: countryObj.countryCode,
                    }}
                    onChange={(_, newCountry) => {
                      n(`/resources/${newCountry.value.toLocaleLowerCase()}`);
                      setCountry(newCountry.value);
                    }}
                    options={options}
                    isOptionEqualToValue={(a, b) => a.value === b.value}
                    sx={{ width: '100%', minWidth: { sm: '200px' } }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        label={t('components.locationDropdowns.country.label')}
                        placeholder={t('components.locationDropdowns.country.label')}
                        {...params}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </Collapse>
          <Box
            onClick={() => setFilterBoxOpen((prev) => !prev)}
            sx={{
              cursor: 'pointer',
              display: { xs: 'block', sm: 'none' },
              width: '100%',
              textAlign: 'center',
            }}
          >
            <FontAwesomeIcon icon={filterBoxOpen ? faAngleUp : faAngleDown} /> {t('Change Country')}{' '}
            <FontAwesomeIcon icon={filterBoxOpen ? faAngleUp : faAngleDown} />
          </Box>
        </Box>

        <Container maxWidth={'lg'}>
          <CountryResources
            flag={countryObj.flag}
            countryCode={countryObj.countryCode}
            countryName={countryObj.name}
          />
        </Container>
      </Container>
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default Resources;
