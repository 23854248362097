import tenantRightsCaSrc from '../../assets/resourceImages/tenantRightsCa.png';
import governmentOfBcLogo from '../../assets/resourceImages/british-columbia-logo.svg';
import ubcSrc from '../../assets/resourceImages/universityOfBritishColumbia.png';
import sfuSrc from '../../assets/resourceImages/simonFraserUniversity.png';
import acornWideLogo from '../../assets/resourceImages/acornWideLogo.png';
import omhmLogo from '../../assets/resourceImages/omhmLogo.png';
import { SxProps } from '@mui/material';

export type TextEntry = {
  heading?: string;
  paragraphs: string[];
};

export type ResourceMapEntry = {
  imgSrc?: string;
  imgStyle?: SxProps;
  label: string;
  labelStyle?: SxProps;
  description: string;
  href: string;
  textColor?: string;
  backgroundColor?: string;
};

export const resourceMap: {
  [countryCode: string]: {
    countryText?: TextEntry[];
    countryResources?: ResourceMapEntry[];
    stateResources?: {
      [stateCode: string]: {
        stateText?: TextEntry[];
        stateResources: ResourceMapEntry[];
      };
    };
  };
} = {
  AU: {
    countryResources: [
      {
        label: 'Renters and Housing Union',
        description: 'Renters and Housing Union',
        href: 'https://rahu.org.au/join-rahu/',
      },
      {
        label: "New South Wales Tenants' Union",
        description: 'Renters and Housing Union',
        href: 'https://www.tenants.org.au/nato',
      },
    ],
  },
  CA: {
    countryResources: [
      {
        imgStyle: { backgroundColor: 'rgba(255, 255, 255, 1)' },
        href: 'https://acorncanada.org/acorn-tenant-unions/',
        imgSrc: acornWideLogo,
        label: 'ACORN Canada',
        description:
          'ACORN Canada members fight landlords and corporations through direct action. Information in Tenant Unions.',
        backgroundColor: 'rgba(207, 46, 46, 1)',
      },
      {
        imgSrc: tenantRightsCaSrc,
        imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
        href: 'https://tenantrights.ca/',
        label: 'Tenant Rights.ca',
        labelStyle: {},
        description: 'Tenant Rights is a resource for basic rental information in Canada',
        backgroundColor: 'rgba(50, 65, 72, 0.87)',
      },
    ],
    stateResources: {
      AB: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/alberta',
            label: 'Tenant Rights - AB',
            description: 'Alberta specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      BC: {
        stateText: [
          {
            heading: 'Governing Body',
            paragraphs: [
              'The governing body in BC is the Residential Tenancy Branch (RTB) responsible for enforcing the Residential Tenancy Act. The RTB provides dispute resolution, online tools, legal information, and approved forms and notices',
              'Types of of housing not covered by legislation: housing owned and operated by an educational institution (residence hall or dormitory) and available to rent by students and faculty; housing meant for travellers or those on vacation; housing for transitional needs; housing classified under the Mobile Home Park Tenancy Act; in some instances, senior care facilities; and housing that is classified under the Community Care Facility Act, Continuing Care Act, Hospital Act, or Mental Health Act.',
            ],
          },
        ],
        stateResources: [
          {
            label: 'Vancouver Tenants Union',
            imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.93)' },
            labelStyle: { color: 'black' },
            description:
              'The Vancouver Tenants Union represents over 2500 members, and seeks to build a base of tenants throughout the city',
            href: 'https://www.vancouvertenantsunion.ca/',
            backgroundColor: 'rgb(25 125 155)',
          },
          {
            imgSrc: governmentOfBcLogo,
            imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.39)' },
            href: 'https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies',
            label: 'Residential Tenancy Branch',
            description: 'Residential Tenancy Branch - Regulatory body of British Columbia',
            backgroundColor: '#38598a',
          },
          {
            imgSrc: governmentOfBcLogo,
            imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.39)' },
            href: 'https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies/contact-the-residential-tenancy-branch',
            label: 'Contact the RTB',
            description:
              'Contact the Residential Tenancy Branch for information and dispute resolution',
            backgroundColor: '#38598a',
          },
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/british-columbia',
            label: 'Tenant Rights - BC',
            description: 'British Columbia specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
          {
            imgSrc: governmentOfBcLogo,
            imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.39)' },
            href: 'http://www.housing.gov.bc.ca/rtb/WebTools/InterestOnDepositCalculator.html',
            label: 'Deposit Interest Calculator',
            description: 'Calculate how much interest is owed on a security or pet deposit',
            backgroundColor: '#38598a',
          },
          {
            imgSrc: ubcSrc,
            imgStyle: { padding: '3px', backgroundColor: 'rgba(255, 255, 255, 0.7)' },
            label: 'UBC Vancouver Residence Life',
            description:
              'UBC Vancouver - Disputes are handled by the RA and escalated ultimately to the Asociate Directors, Residence Life',
            href: 'https://vancouver.housing.ubc.ca/solving-problems/',
            backgroundColor: 'rgb(12, 35, 68)',
          },
          {
            imgSrc: ubcSrc,
            imgStyle: { padding: '3px', backgroundColor: 'rgba(255, 255, 255, 0.7)' },
            label: 'UBC Okanagan Residence Life',
            description:
              'UBC Okanagan - Disputes are handled by the RA and escalated ultimately to the Asociate Directors, Residence Life',
            href: 'https://okanagan.housing.ubc.ca/residence-life/solving-problems/',
            backgroundColor: 'rgb(12, 35, 68)',
          },
          {
            imgSrc: sfuSrc,
            imgStyle: { padding: '3px', backgroundColor: 'rgba(255, 255, 255, 0.7)' },
            label: 'SFU Residence Housing',
            description:
              'SFU - Disputes are handled by the Community Advisor and escalated ultimately to the Director, Residence and Housing',
            href: 'https://www.sfu.ca/students/residences/community/contract-handbook.html',
            backgroundColor: 'rgb(166, 25, 46)',
          },
        ],
      },
      MB: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/manitoba',
            label: 'Tenant Rights - MB',
            description: 'Manitoba specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      NB: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/new-brunswick',
            label: 'Tenant Rights - NB',
            description: 'New Brunswick specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      NL: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/newfoundland-and-labrador',
            label: 'Tenant Rights - NL',
            description: 'Newfoundland specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      NT: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/northwest-territories',
            label: 'Tenant Rights - NT',
            description: 'Northwest Territories specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      NS: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/nova-scotia',
            label: 'Tenant Rights - NS',
            description: 'Nova Scotia specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      NU: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/nunavut',
            label: 'Tenant Rights - NU',
            description: 'Nunavut specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      ON: {
        stateResources: [
          {
            label: "Federation of Metro Tenants' Association",
            description:
              "The Federation of Metro Tenants' Associations (FMTA) is a non-profit organization which advocates for better rights for tenants.",
            href: 'https://www.torontotenants.org/get_involved',
          },
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/ontario',
            label: 'Tenant Rights - ON',
            description: 'Ontario specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      PE: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/prince-edward-island',
            label: 'Tenant Rights - PEI',
            description: 'PEI specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      QC: {
        stateResources: [
          {
            imgSrc: omhmLogo,
            imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.87)' },
            href: 'https://www.omhm.qc.ca/en/community-life/actively-involved-tenants',
            label: 'OMHM',
            description:
              "Office municipal d'habitation de Montréal - Tenants taking charge of their living environments",
            backgroundColor: 'rgba(232, 232, 232, 1)',
            textColor: 'rgba(0, 0, 0, 1)',
          },
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/quebec',
            label: 'Tenant Rights - QC',
            description: 'Quebec specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      SK: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/saskatchewan',
            label: 'Tenant Rights - SK',
            description: 'Saskatchewan specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
      YT: {
        stateResources: [
          {
            imgSrc: tenantRightsCaSrc,
            imgStyle: { backgroundColor: 'rgba(50, 65, 72, 0.87)' },
            href: 'https://tenantrights.ca/facts/yukon',
            label: 'Tenant Rights - YU',
            description: 'Yukon specific fact Sheet',
            backgroundColor: 'rgba(50, 65, 72, 0.87)',
          },
        ],
      },
    },
  },
  NZ: {
    countryResources: [
      {
        label: 'Tenants Protection Association',
        description: 'Te Tōpū Tiaki-ā-Kainoho',
        href: 'https://www.tpa.org.nz/',
      },
    ],
    stateResources: {
      AUK: {
        stateResources: [
          {
            label: 'Tenants Protection Association',
            description: 'Tenants Protection Association',
            href: 'https://tpaauckland.org.nz/',
          },
        ],
      },
    },
  },
  GB: {
    countryResources: [
      {
        label: 'ACORN UK',
        imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.15)' },
        description: 'ACORN UK',
        href: 'https://acorntheunion.org.uk/',
        backgroundColor: 'rgba(207, 46, 46, 1)',
      },
      {
        label: 'London Renters Union',
        description: 'London Renters Union',
        href: 'https://londonrentersunion.org/',
      },
      {
        label: 'ACORN Birmingham',
        imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.15)' },
        description: 'Twitter (X) - ACORN Birmingham',
        href: 'https://twitter.com/AcornBirmingham',
        backgroundColor: 'rgba(207, 46, 46, 1)',
      },
      {
        label: 'ACORN Liverpool',
        imgStyle: { backgroundColor: 'rgba(255, 255, 255, 0.15)' },
        description: 'Twitter (X) - ACORN Liverpool',
        href: 'https://twitter.com/AcornLiverpool',
        backgroundColor: 'rgba(207, 46, 46, 1)',
      },
    ],
  },
  US: {
    countryResources: [
      {
        label: 'abolitionnotes.org',
        description: 'Guide to Tenants Unions in the United States',
        href: 'https://abolitionnotes.org/a-quick-guide-to-all-tenants-unions-in-the-us',
      },
      {
        label: 'Housing and Urban Development',
        description:
          'Resources for rental assistance. Find an appartment, public housing, and voucher information',
        href: 'https://www.hud.gov/topics/rental_assistance',
      },
      {
        label: 'Housing and Urban Development',
        description: 'Housing counseling services',
        href: 'https://hudgov-answers.force.com/housingcounseling/s',
      },
    ],
    stateResources: {
      AL: {
        stateResources: [
          {
            label: 'Center for Dispute Resolution',
            description: 'Find a Landlord-Tenant Mediator',
            href: 'https://www.alabamaadr.org/web/roster-landlord-tenant/index.php',
          },
        ],
      },
      AK: { stateResources: [] },
      AZ: { stateResources: [] },
      AR: { stateResources: [] },
      CA: {
        stateResources: [
          {
            label: 'Los Angeles Tenants Union',
            description:
              'The LA Tenants Union is a diverse, tenant-led movement fighting for the human right to housing for all',
            href: 'https://latenantsunion.org/en/',
          },
        ],
      },
      CO: { stateResources: [] },
      CT: { stateResources: [] },
      DE: { stateResources: [] },
      DC: { stateResources: [] },
      FL: { stateResources: [] },
      GA: { stateResources: [] },
      GU: { stateResources: [] },
      HI: { stateResources: [] },
      ID: { stateResources: [] },
      IL: {
        stateResources: [
          {
            label: 'Autonomous Tenants Union',
            description:
              'Autonomous Tenants Union is an all-volunteer organization committed to organizing for housing justice',
            href: 'https://www.autonomoustenantsunion.org/',
          },
        ],
      },
      IN: { stateResources: [] },
      IA: { stateResources: [] },
      KS: { stateResources: [] },
      KY: { stateResources: [] },
      LA: { stateResources: [] },
      ME: { stateResources: [] },
      MD: { stateResources: [] },
      MA: { stateResources: [] },
      MI: { stateResources: [] },
      MN: { stateResources: [] },
      MS: { stateResources: [] },
      MO: { stateResources: [] },
      MT: { stateResources: [] },
      NE: { stateResources: [] },
      NV: { stateResources: [] },
      NH: { stateResources: [] },
      NJ: { stateResources: [] },
      NM: { stateResources: [] },
      NY: {
        stateResources: [
          {
            label: 'Tenants & Neighbors',
            description: 'A members-led statewide center for tenant power in New York',
            href: 'https://www.tandn.org/',
          },
        ],
      },
      NC: { stateResources: [] },
      ND: { stateResources: [] },
      OH: { stateResources: [] },
      OK: { stateResources: [] },
      OR: { stateResources: [] },
      PA: { stateResources: [] },
      PR: { stateResources: [] },
      RI: { stateResources: [] },
      SC: { stateResources: [] },
      SD: { stateResources: [] },
      TN: { stateResources: [] },
      TX: { stateResources: [] },
      VI: { stateResources: [] },
      UT: { stateResources: [] },
      VT: { stateResources: [] },
      VA: { stateResources: [] },
      WA: { stateResources: [] },
      WV: { stateResources: [] },
      WI: { stateResources: [] },
      WY: { stateResources: [] },
    },
  },
};
