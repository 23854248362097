import { Container, Typography, Box, Grid, Divider, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ResourceMapEntry, resourceMap } from './resourceMap';
import { FunctionComponent, useState } from 'react';
import { useLocationData } from '../../hooks/useLocationData';
import NotFound from './NotFound';
import ExpandMore from '../../components/ExpandMore';

const ResourceBox: FunctionComponent<ResourceMapEntry> = ({
  label,
  labelStyle,
  description,
  imgStyle,
  imgSrc,
  textColor,
  backgroundColor,
  href,
}) => (
  <Grid
    container
    bgcolor={backgroundColor}
    height={'100%'}
    padding={'4px'}
    borderRadius={'4px'}
    sx={{
      color: textColor || '#FFF',
      background: backgroundColor || '#4d6474',
      cursor: 'pointer',
    }}
    minHeight={'150px'}
  >
    <a style={{ color: 'inherit', width: 'inherit' }} href={href} target="_blank" rel="noopener">
      <Box
        height={'36px'}
        width={'100%'}
        display={'flex'}
        boxSizing={'border-box'}
        sx={{ borderRadius: '4px', background: '#334856', ...imgStyle }}
      >
        {imgSrc ? (
          <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={imgSrc} />
        ) : (
          <Typography
            title={label}
            sx={{
              paddingLeft: '10px',
              my: 'auto',
              overflow: 'hidden',
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              ...labelStyle,
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
      <Box height={'100%'} maxWidth={'100%'}>
        <Typography pl={'10px'}>{description}</Typography>
      </Box>
    </a>
  </Grid>
);

const CountryResources: FunctionComponent<{
  flag: string;
  countryName: string;
  countryCode: string;
}> = ({ flag, countryName, countryCode }) => {
  const { getStateByCodeAndCountry } = useLocationData();
  const [expanded, setExpanded] = useState<string | null>(null);
  const countryResourceMap = resourceMap[countryCode];

  if (!countryResourceMap) return <NotFound countryCode={countryCode} />;

  const handleExpandClick = (stateCode: string) => {
    setExpanded((prev) => (prev === stateCode ? null : stateCode));
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: { xs: '10px', sm: null },
      }}
    >
      <Typography variant="h1">
        {flag} {countryName}
      </Typography>

      {countryResourceMap.countryResources ? (
        <Grid container spacing={'5px'}>
          {countryResourceMap.countryResources.map((resource, idx) => (
            <Grid key={`countryResource-${idx}`} item xs={12} sm={6} md={4}>
              <ResourceBox {...resource} />
            </Grid>
          ))}
        </Grid>
      ) : null}

      {countryResourceMap.countryResources && countryResourceMap.stateResources ? (
        <Divider />
      ) : (
        <></>
      )}

      {countryResourceMap.stateResources
        ? Object.entries(countryResourceMap.stateResources)
            .filter(([_, { stateResources }]) => stateResources && stateResources.length > 0)
            .map(([stateCode, { stateText, stateResources }], idx) => (
              <Box key={`stateResource-${idx}`}>
                <Typography
                  variant="h2"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleExpandClick(stateCode)}
                >
                  {getStateByCodeAndCountry(countryCode, stateCode)?.name || ''}{' '}
                  <ExpandMore
                    expand={expanded === stateCode}
                    aria-expanded={expanded === stateCode}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Typography>
                <Collapse in={expanded === stateCode} timeout="auto">
                  {stateText &&
                    stateText.map(({ heading, paragraphs }, stateTextIdx) => {
                      return (
                        <Box
                          key={`stateText-${stateCode}-${stateTextIdx}`}
                          sx={{ p: { xs: '10px' } }}
                        >
                          {heading && (
                            <Typography sx={{ fontSize: { xs: '1rem' } }} variant="h3">
                              {heading}
                            </Typography>
                          )}
                          {paragraphs.map((paragraph, stateTextParaIdx) => (
                            <Typography
                              key={`stateTextPara-${stateCode}-${stateTextParaIdx}`}
                              sx={{ textIndent: '3%' }}
                            >
                              {paragraph}
                            </Typography>
                          ))}
                        </Box>
                      );
                    })}

                  <Grid container spacing={2}>
                    {stateResources.map((resource, idxx) => (
                      <Grid key={`stateResource-${stateCode}-${idxx}`} item xs={12} sm={6} md={4}>
                        <ResourceBox {...resource} />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Box>
            ))
        : null}
    </Container>
  );
};

export default CountryResources;
