import { Box, Container, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Country, useLocationData } from '../../hooks/useLocationData';
import { useParams } from 'react-router-dom';
import { useModalStateStore } from '../../state/modals';
import LoadingSpinner from '../../components/LoadingSpinner';

const NotFound: FunctionComponent<{ countryCode?: string }> = (props) => {
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));
  const { isLoaded, getCountryByCode } = useLocationData();
  const { countryCode } = useParams<{ countryCode?: string }>();

  const [country, setCountry] = useState<Country | undefined>(undefined);

  useEffect(() => {
    if (!isLoaded) return;
    if (!countryCode && !props.countryCode) {
      window.location.href = '/resources';
      return;
    }

    const foundCountry = getCountryByCode(
      props.countryCode || countryCode?.toLocaleUpperCase() || ''
    );
    if (!foundCountry) {
      window.location.href = '/resources';
      return;
    } else return setCountry(foundCountry);
  }, [isLoaded, countryCode]);

  if (!isLoaded || !country) return <LoadingSpinner />;

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: { xs: '10px', sm: null },
      }}
    >
      <Typography variant="h1">{`${country.flag} ${country.name}`}</Typography>
      <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
        <Typography>
          Unfortunately we don't have any resources for {country.name}. If you send any to us via
          our <a onClick={openContactUsModal}>contact form</a>, we'd be happy to review and add
          them.
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;
